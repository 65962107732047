import React from "react";
import styled from "styled-components";

const ButtonLink = styled.a``;

const ArchivLink = () => (
  <div className="text-centered has-padding-bottom">
    <ButtonLink className="btn orange" href="/news#0">
      &nbsp;&nbsp;Weitere News&nbsp;&nbsp;
    </ButtonLink>
  </div>
);

export default ArchivLink;
