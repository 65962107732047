import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import DefaultLayout from "../layouts/default";
import Footer from "../components/Footer";
import NewsGrid from "../components/NewsGrid";
import "../sass/screen.scss";
import ArchivLink from "../components/ArchivLink";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        entries(orderBy: "postDate desc", limit: 6) {
          title
          url
          uri
          id
          postDate
          ... on CMS_news_news_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1212_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1323_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
          ... on CMS_news_news1434_Entry {
            prevtitel: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_titel_BlockType {
                titel
              }
            }
            prevlead: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_lead_BlockType {
                lead
              }
            }
            prevtext: matrixNewsPreview {
              ... on CMS_matrixNewsPreview_text_BlockType {
                text
              }
            }
          }
        }
      }
    }
  `);

  return (
    <DefaultLayout>
      <div className="home">
        <div className="navContentSpacerHelperSingleNavBar"></div>
        <header className="navWrapper">
          <nav className="mainNav singleNavBar">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <div className="newsBackgroundBlur">
            <section className="mainBanner jahr35">
              <div className="wrapper has-padding-top has-padding-bottom">
                <div className="row">
                  <div className="column">
                    <h2>klar | bewährt | zielführend</h2>
                    <h1>
                      Seit 1987 tragen unsere Software&shy;lösungen nachweislich
                      dazu bei, die Ärzte, das Pflege&shy;personal und die
                      Klinik- sowie Heimleitung spürbar zu entlasten.
                    </h1>
                  </div>
                  <div className="column jahr35Icon">
                    <img src="/assets/images/icons/35jahre.svg" alt="" />
                  </div>
                  {/* 
                  <a
                    className="stoerer"
                    href="https://www.ifas-expo.ch/de/p/wigasoft-ag.134116"
                    target="_blank"
                  >
                    <img src="/assets/images/gestaltungselemente/IFAS_Label_2024.png" />
                  </a> */}
                </div>
              </div>

              {/* Deactivater for 35th 
              <div className="background">
                <img
                  className="js-img-size-from-parent"
                  ix-src="https://wigasoft-prod.imgix.net/backgrounds/bkg-wigasoft.png?fit=crop&amp;auto=format,compress"
                  src="https://wigasoft-prod.imgix.net/backgrounds/bkg-wigasoft.png?q=50&w=1200&h=400"
                />
              </div> */}
            </section>
            {/* <div className="stoererMobileWrapper wrapper has-padding-top has-padding-bottom">
              <a
                className="stoererMobile"
                href="https://www.ifas-expo.ch/de/p/wigasoft-ag.129910"
                target="_blank"
              >
                <img src="/assets/images/gestaltungselemente/IFAS_Label.png" />
              </a>
            </div> */}

            <div className="wrapper">
              <NewsGrid entries={data.cms.entries} />
              <ArchivLink />              
              {/* <section className="news newsFlex">
                <div className="subFlex1"></div>
              </section>
              <div className="newsBottomFade" onClick="loadNewsElements();">
                <div className="newsLoadButton"></div>
              </div> */}
            </div>

            <section className="mainBanner bannerSwan">
              <div className="wrapper">
                <div className="arbitraryContainerclassName">
                  <div className="swanBannerFlex">
                    <div className="swanImg">
                      <img src="https://wigasoft-prod.imgix.net/swan_new.png?auto=format,compress&w=700" />
                    </div>
                    <div className="text-left swanBannerPadding">
                      <h2>WigaSoft AG</h2>
                      <h1>
                        Über 35 Jahre <br />
                        Software&shy;entwicklung <br />
                        für das Gesundheits&shy;wesen.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="has-padding-topic is-background-color-grey">
              <div className="wrapper">
                <div className="halfFlex epdContainer">
                  <div className="smallStaticPadding-bottom">
                    <h6 className="smallStaticPadding-bottom ">
                      Anbindung elektronisches Patiendossier EPD
                    </h6>
                    <p className="fliesstext3 has-padding-bottom">
                      Als Anbieter und Betreiber von Primärsystemen für die
                      Pflegedokumentation ist es für uns eine
                      Selbstverständlichkeit, unsere Kunden auf dem Weg in die
                      Zukunft mit eHealth und dem elektronischen
                      Patientendossier EPD zu begleiten. <br />
                      <br />
                      Für weiterführende Informationen stehen wir gerne zur
                      Verfügung.
                    </p>
                  </div>
                  <div>
                    <img
                      className="imgix no-crop js-img-size-from-parent"
                      src="/assets/images/logos/WS_EPD-Logo_10_2018.png?q=50&w=1200&h=400"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="has-padding-top">
              <div className="wrapper">
                <div className="halfFlex">
                  <div className="smallStaticPadding-bottom">
                    <h6 className="smallStaticPadding-bottom">
                      WiCare|now DOC
                      <br />– mobil unterwegs
                    </h6>
                    <Link to="/dokumentationsloesungen/wicare-doc-l/mobile/">
                      <img
                        align="left"
                        className="smallStaticPadding-right smallStaticPadding-bottom"
                        src="/assets/images/logos/WCN-Doc.svg"
                        width="200px"
                        height="200px"
                      />
                    </Link>
                    <p className="fliesstext3 has-padding-bottom">
                      WiCare|now DOC ist unsere mobile Lösung für den
                      Pflegealltag. Mit den von uns bereitgestellten Komponenten
                      kann unterwegs jederzeit auf die aktuelle Dokumentation
                      zugegriffen werden.
                      <br />
                      WiCare|now besteht aus dem Core, der Basis-Komponente für
                      die Abbildung der Organisation und Berechtigungen. Mit den
                      WiCare|now Komponenten wählen Sie die für Sie relevanten
                      Bausteine aus, um den Pflegeprozess bedarfsgerecht
                      abzubilden.
                    </p>
                  </div>
                  <div>
                    <img
                      className="tabletImg imgix no-crop js-img-size-from-parent"
                      src="/assets/images/WCN-Doc_Home_mit_Hand.png?q=50&w=1200&h=400"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="cContainter noPadding noMargin mainBanner is-background-color is-color-white has-neg-margin-10-top swissnessPadding smallStaticMargin-bottom">
              <div className="wrapper">
                <div className="swissnessHomeFlex">
                  <div className="is-visible-tablet-hor text-right">
                    <img
                      src="/assets/images/quotes.svg"
                      width="161px"
                      className="quotesSwissness"
                    />
                  </div>
                  <div>
                    <h2>Swissness</h2>
                    <h4 className="headline1">
                      Softwarelösungen der WigaSoft werden vollständig in der
                      Schweiz entwickelt, eingeführt und betreut.
                    </h4>
                  </div>
                </div>
              </div>
            </section>

            <div className="wrapper has-padding-top has-padding-large-bottom">
              <section className="has-padding-top">
                <div className="wrapperFlexTabletBreak has-neg-margin-50-top-PC-only has-padding-bottom">
                  <div>
                    <a href="/dokumentationsloesungen/wicare-le/">
                      <img
                        className="has-max-width-320 smallStaticPadding-bottom"
                        src="/assets/images/logos/WCN-LE.svg"
                        style={{ width: 200 }}
                      />
                    </a>
                    <h5 className="smallStaticPadding-bottom">WiCare|now LE</h5>
                    <p className="fliesstext4 smallStaticPadding-bottom">
                      WiCare|now LE ist unser webbasierte
                      Leistungserfassungslösung – mit WiCare|now LE können
                      Leistungen für die interne Kostenrechnung sowie für die
                      Fakturierung erfasst werden. Es werden die Regelwerke der
                      verwendeten Tarife berücksichtigt.
                    </p>
                  </div>
                  <div>
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      <img
                        className="has-max-width-320 smallStaticPadding-bottom"
                        src="/assets/images/logos/WCN-LEP.svg"
                        style={{ width: 200 }}
                      />
                    </a>
                    <h5 className="smallStaticPadding-bottom">
                      WiCare|now LEP<sup>®</sup>
                    </h5>
                    <p className="fliesstext4 smallStaticPadding-bottom">
                      WiCare|now LEP<sup>®</sup> ist unsere webbasierte,
                      plattformunabhängige Softwarelösung für die
                      standardisierte und logische Tätigkeits- und
                      Leistungserfassung, sowie zur Erstellung der LEP
                      <sup>®</sup>-Auswertungen, unter Berücksichtigung der
                      Vorgaben der LEP AG.
                    </p>
                  </div>
                  <div>
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      <img
                        className="has-max-width-320 smallStaticPadding-bottom"
                        src="/assets/images/logos/WCN-tacs.svg"
                        style={{ width: 200 }}
                      />
                    </a>

                    <h5 className="smallStaticPadding-bottom">
                      WiCare|now tacs<sup>®</sup>
                    </h5>
                    <p className="fliesstext4 smallStaticPadding-bottom">
                      WiCare|now tacs<sup>®</sup> ist das tacs<sup>®</sup> V2
                      zertifizierte, webbasierte, plattformunabhängige
                      Controllingsystem zur Tätigkeitsanalyse mit integrierter
                      Leistungserfassung nach der Methode tacs<sup>®</sup>.
                      WiCare|now tacs<sup>®</sup> eignet sich für den Einsatz
                      bei verschiedenen Berufsgruppen. Die Pflegetätigkeiten
                      werden klar und transparent dargestellt, die daraus
                      resultierenden Führungskennzahlen ermöglichen dem
                      Management die Prozesse zu steuern.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
        <svg
          className="blur blurHiddden"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <defs>
            <filter id="blur">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="10,10"
              ></feGaussianBlur>
            </filter>
          </defs>
        </svg>
      </div>
    </DefaultLayout>
  );
};

export default IndexPage;
